import Swiper from 'swiper';
import {add_swiper_class} from '@cabourg/js/tools/swiper';
import Modernizr from 'modernizr';

export default class Slider {

	constructor() {
		Slider.hero();
		Slider.related_post();
	}

	/**
	 * Slider banner
	 */
	static hero() {

		let slider = '[data-id="wpiris-hero-slider"]',
			slider_wrapper = '[data-id="wpiris-hero-slider-wrapper"]',
			slider_item = '[data-id="wpiris-hero-slider-item"]';

		if ($(slider).length > 0) {

			add_swiper_class($(slider), $(slider_wrapper), $(slider_item));

			let options = {
				mousewheel: false,
				autoplay: {
					delay: 5000
				},
				speed: 800,
				lazy: true,
				loop: true,
				watchSlidesProgress: true,
				parallax: true,
				navigation: {
					nextEl: '[data-trigger="swiper-next"]',
					prevEl: '[data-trigger="swiper-prev"]',
				},
			};

			let sliderEl = new Swiper($(slider), options);

			$("[data-trigger='interactive-thumbnail-hotpoint']").hover(function () {
				sliderEl.autoplay.stop();
			}, function () {
				sliderEl.autoplay.start();
			});
		}

	}

	/**
	 * Slider contenu relatif
	 */
	static related_post() {

		let slider = '[data-id="wpiris-related-post"]',
			slider_wrapper = '[data-id="wpiris-related-post-wrapper"]',
			slider_item = '[data-id="wpiris-related-post-item"]';

		if ($(slider).length > 0) {

			$(slider).each(function () {

				add_swiper_class($(slider), $(slider_wrapper), $(slider_item));

				let slides_per_view = undefined !== $(this).attr('data-slides-per-view') ? $(this).attr('data-slides-per-view') : 3,
					slides_per_view_large = undefined !== $(this).attr('data-slides-per-view-large') ? $(this).attr('data-slides-per-view-large') : 3,
					slides_per_view_medium = undefined !== $(this).attr('data-slides-per-view-medium') ? $(this).attr('data-slides-per-view-medium') : 2,
					slides_per_view_small = undefined !== $(this).attr('data-slides-per-view-small') ? $(this).attr('data-slides-per-view-small') : 2,
					space_between = undefined !== $(this).attr('data-space-between') ? $(this).attr('data-space-between') : 40,
					space_between_large = undefined !== $(this).attr('data-space-between-large') ? $(this).attr('data-space-between-large') : 40,
					space_between_medium = undefined !== $(this).attr('data-space-between-medium') ? $(this).attr('data-space-between-medium') : 20,
					space_between_small = undefined !== $(this).attr('data-space-between-small') ? $(this).attr('data-space-between-small') : 10;

				// Controls
				let prev = $(this).find('.swiperArrow--prev'),
					next = $(this).find('.swiperArrow--next');

				new Swiper($(this), {
					mousewheel: {
						forceToAxis: true,
					},
					slidesPerView: slides_per_view_small,
					spaceBetween: space_between_small,
					speed: 600,
					keyboard: true,
					freeMode: true,
					watchSlidesVisibility: true,
					passiveListeners: false,
					navigation: {
						nextEl: next,
						prevEl: prev
					},
					breakpoints: {
						650: {
							slidesPerView: slides_per_view_medium,
							spaceBetween: space_between_medium,
						},
						1000: {
							slidesPerView: slides_per_view_large,
							spaceBetween: space_between_large,
						},
						1381: {
							slidesPerView: slides_per_view,
							spaceBetween: space_between,
						}
					}
				});

			});
		}
	}
}
