export default class Menu {

	constructor()
	{
		Menu.messenger();
		if ( window.matchMedia( "(max-width: 1000px)" ).matches ) {
			Menu.burger_menu();
		} else {
			Menu.submenu_toggle();
		}
	}
	static messenger() {
		const trigger = '[data-trigger="wpiris-show-messenger"]';

		$(document).on('click', trigger, function (e) {
			FB.CustomerChat.showDialog();
		});
	}

	static submenu_toggle() {
		let trigger = '[data-trigger="wpiris-show-submenu"]',
			element = '[data-id="wpiris-submenu"]',
			item = '[data-id="wpiris-menu-item"]',
			close = '[data-trigger="wpiris-modal-toggle"].irismodalClose'

		$(element + '.active').slideToggle();

		let timeout;

		$(document).on('click', close, function (e) {
			$('[data-trigger="wpiris-show-submenu"]').removeClass('is-expanded');
			$(element + '.active').removeClass('active').slideUp();
			$(item).removeClass('is-active');

			setTimeout(function(){
				$(item).first().addClass('is-active');
				$('[data-trigger="wpiris-show-submenu"]').first().addClass('is-expanded');
				$(element).first().addClass('active').slideDown()
			}, 500)
		});

		$(document).on('mouseover', trigger, function (e) {
			let ths = $(this)
			clearTimeout(timeout);

			timeout = setTimeout(function(){
				e.preventDefault();
				$(item).removeClass('is-active');
				let is_active = false;

				if ($(ths).parent('li').find(element).hasClass('active')) {
					is_active = true;
				}

				$('[data-trigger="wpiris-show-submenu"]').removeClass('is-expanded');
				$(ths).addClass('is-expanded');
				$(element + '.active').removeClass('active').slideUp();

				if (false == is_active) {
					$(ths).parent('li').addClass('is-active').find(element).addClass('active').slideToggle();
				}
			}, 200)
		})
	}

	static burger_menu() {
		const $element = $('[data-id="wpiris-menu"]'),
			elementItem = '[data-id="wpiris-submenu"]',
			elementItemParent = '[data-id="wpiris-menu-item"].withChildrens > a, [data-id="wpiris-menu-item-submenu"].withChildrens > a',
			triggerMore = '[data-trigger="wpiris-show-submenu-arrow"]',
			triggerBack = '[data-trigger="wpiris-menu-back"]';

		$(document).on('click', triggerMore, function (e) {
			let parent = $(this).closest('[data-id="wpiris-menu-item"]');
			if( $(parent).find(elementItem).length > 0 ){
				e.preventDefault();
				$(elementItem).removeClass('submenuActive');
				$(parent).find(elementItem).addClass('submenuActive');
			}
		});

		$(document).on('click', triggerBack, function (e) {
			$(this).closest(elementItem).removeClass('submenuActive');
		});

		$(document).on('click', elementItemParent, function (e) {
			e.preventDefault();
			$(elementItem).removeClass('submenuActive');
			$(this).parents(elementItem).addClass('submenuActive');
		});
	}
}
