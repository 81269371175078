export default class Form {

	constructor()
	{
		Form.select();
		Form.submitForm();
		Form.input_focused();
	}

	static select()
	{
		$( 'select' ).parent().not( '.formSelect' ).children( 'select' ).wrap( '<div class="formSelect"></div>' );
	}

	static submitForm()
	{
		$( document ).on( 'submit', '#form_formulairedecontact', function ( e ) {
			$( document ).scrollTop();
		} );
	}

	static input_focused()
	{
		const $input = $( ".formGroup--text input, .wpet-select-item .formGroup--text input, .formGroup--textarea textarea, .formGroup--select select, .formGroup--phone input, .formGroup--number input, .formGroup--date input, .formGroup--email input, #newsletter-field, .wpSearchFormItem--input" );

		$input.each( function () {
			if ( $( this ).val() && $( this ).val().length != 0 || $( this ).attr( 'placeholder' ) !== undefined ) {

				$( this ).closest( '.formGroup' ).addClass( 'is-focused' );
			}
		} );

		$( document ).on( 'focusin change','.formGroup:not(.no-animate)', function ( e ) {
			$( this ).addClass( 'is-focused' );
		} );

		$( document ).on( 'focusout change','.formGroup:not(.no-animate)', function ( e ) {
			let input    = $( this ).find( 'input' );
			let textarea = $( this ).find( 'textarea' );
			let select   = $( this ).find( 'select' );

			if ( (
				!$( input ).val()
			) && (
				!$( textarea ).val()
			) && (
				!$( select ).val()
			) ) {
				$( this ).removeClass( 'is-focused' );
			}
		} );
	}
}
