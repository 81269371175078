/**
 *
 * @param container
 * @param wrapper
 * @param item
 */
export const add_swiper_class = ( container, wrapper, item ) =>
{
	$( container ).addClass( 'swiper-container' );
	$( wrapper ).addClass( 'swiper-wrapper' );
	$( item ).addClass( 'swiper-slide' );
}

/**
 *
 * @param element
 */
export const swiper_destroy = ( element ) =>
{
	$( element ).each( function () {
		this.swiper.destroy();
	} );
}