import '@cabourg/scss/app-front-page.scss';
import Utils from '@cabourg/js/services/utils';
import Form from '@cabourg/js/services/form';
import Slider from '@cabourg/js/services/slider';
import Menu from '@cabourg/js/services/menu';
import Frontpage from '@cabourg/js/services/frontpage';

document.addEventListener("DOMContentLoaded", function () {

	new Form();
	new Utils();
	new Menu();
	new Frontpage();

	// Slider
	Slider.hero();
	Slider.related_post();
	Utils.initialize_language();
	Utils.replace_broken_image();
})
