import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/tab';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/popover';
import 'bootstrap/js/src/tooltip';
import 'bootstrap/js/src/modal';
import Modernizr from 'modernizr';
import LazyLoad from "vanilla-lazyload";
import Isotope from 'isotope-layout/dist/isotope.pkgd.min';
import {get_cookie, set_cookie} from '@cabourg/js/tools/cookie';

export default class Utils {

	constructor() {
		Utils.click();
		Utils.bootstrap();
		Utils.fancybox();
		Utils.modal();
		Utils.smooth_scroll();
		Utils.lazy();
		Utils.block_cover();
		Utils.obfuscation();
		if (window.matchMedia("(min-width: 650px)").matches) {
			Utils.sticky_navbar();
		}
		Utils.show_more();
		Utils.isotope();
		Utils.live_hour();
		Utils.live_toggle();
		Utils.notification_modal();
		Utils.replace_broken_image();
		Utils.switch();
		Utils.faq();
		Utils.translate();
	}

	static replace_broken_image() {
		// For local environment only
		if (window.location.hostname.match(/.iris.io/)) {
			// let preprod_url = 'https://otcabourg.preprod5.irislab.top';
			// $('img').on('error', function (e) {
			// 	let src = $(this).attr('src');
			// 	let new_src = src.replace(Theme.server_url, preprod_url);
			// 	$(this).attr('src', new_src);
			// });
			$('img').attr('src', 'https://otcabourg.preprod5.irislab.top/wp-content/uploads/fly-images/4368/effet-mer-1600x900.jpg');
		}
	}

	static click() {
		const trigger = '[data-trigger="wpiris-click"]';

		if (!Modernizr.touchevents) {
			let ctrl = 0;
			$(window).on('keydown', function (e) {
				if (e.which == 17 || e.which == 91) {
					ctrl = true
				}
			})
			$(window).on('keyup', function () {
				ctrl = false
			})

			$(document).on('mouseup', trigger, function (e) {

				if (e.target.tagName.toLowerCase() !== 'a') {
					if (e.which == 1 || e.which == 2) {
						let blank = false
						let url = $(this).find("a").attr("href")
						if (e.which == 2) {
							blank = true
						} // Clic molette
						if ($(this).find("a").attr("target") == "_blank") {
							blank = true
						}
						if (blank || ctrl) {
							window.open(url, '_blank')
						} else {
							window.location = url
						}
						return false
					}
				}
				return false

			})
		} else {
			$(document).on('click', trigger, function (e) {

				let blank = false
				let url = $(this).find("a").attr("href")
				if ($(this).find("a").attr("target") == "_blank") {
					blank = true
				}
				if (blank) {
					window.open(url, '_blank')
				} else {
					window.location = url
				}
				return false

			})
		}
	}

	static bootstrap() {
		if (!Modernizr.touchevents) {
			$('[data-toggle="tooltip"]').tooltip({trigger: "hover"})
		}

		$('[data-toggle="popover"]').popover();
	}

	static fancybox() {

		$('.hentry a:has(>img):not([target="_blank"]):not("[nofancybox]")').each(function () {

			const href = $(this).attr('href');

			if (href.match(/(.jpg|.jpeg|.png|.gif|.svg|.webp)$/)) {
				$(this).attr('data-fancybox', 'fancybox');
			} else {
				if (href.indexOf(Theme.server_url) === -1) {
					$(this).attr('target', '_blank');
				}
			}
		});


		// let counter = 0;
		// $('.hentry .wp-block-gallery').each(function () {
		// 	counter++;
		// 	$(this).find('a:has(>img)').attr('data-fancybox', 'wp-block-gallery-' + counter);
		// });
		//
		// counter = 0;
		// $('.hentry .wp-block-image').each(function () {
		// 	counter++;
		// 	$(this).find('a:has(>img)').attr('data-fancybox', 'wp-block-image-' + counter);
		// });

		$('[data-fancybox]').fancybox({
			buttons: [
				"zoom",
				"slideShow",
				"close"
			],
			backFocus: false,
			lang: "fr",
			i18n: {
				fr: {
					CLOSE: Theme.translate.fancybox_close,
					NEXT: Theme.translate.fancybox_next,
					PREV: Theme.translate.fancybox_prev,
					ERROR: Theme.translate.fancybox_error,
					PLAY_START: Theme.translate.fancybox_start,
					PLAY_STOP: Theme.translate.fancybox_stop,
					FULL_SCREEN: Theme.translate.fancybox_full_screen,
					THUMBS: Theme.translate.fancybox_thumbs,
					DOWNLOAD: Theme.translate.fancybox_download,
					SHARE: Theme.translate.fancybox_share,
					ZOOM: Theme.translate.fancybox_zoom
				},
			},
			caption: function (instance, item) {
				let caption = $(this).closest('figure').find('figcaption').html()

				if (!caption) {
					caption = $(this).find('img').attr('title')
				}

				if (!caption) {
					caption = $(this).find('img').attr('alt')
				}

				return caption
			}
		})
	}

	static modal() {
		const $element = $('[data-id="wpiris-modal"]'),
			trigger = '[data-trigger="wpiris-modal-toggle"]';

		$(document).on('click', trigger, function (e) {
			e.preventDefault();
			let target = '[data-id="wpiris-modal"]' + $(this).attr('href');
			$(target).toggleClass('open');
			if($(target).hasClass('open') && $(target).find('input[type="text"]')){
				setTimeout(function() {
					$(target).find('input[type="text"]').first().focus()
				}, 200);
			}
			$('body').toggleClass('modalOpened');
		})
	}

	static smooth_scroll() {
		const trigger = '[data-trigger="wpiris-scroll"]';

		$(document).on('click', trigger, function (e) {
			e.preventDefault();

			const target = $(this).attr("href") != '' ? $(this).attr("href") : $(this).attr("data-href"),
				duration = 1000,
				scroll_top = $(target).offset().top - (undefined !== e.currentTarget.dataset.shift ? e.currentTarget.dataset.shift : 120);

			$('html, body').animate({
				scrollTop: scroll_top
			}, {
				duration: duration,
				step: (now, fx) => {
					let real_pos = scroll_top;
					if (fx.end !== real_pos) {
						fx.end = real_pos;
					}
				}
			});
		})
	}

	static lazy() {

		const lazyLoadInstance = new LazyLoad({
			elements_selector: '[data-src], [data-bg], [data-bg-hidpi]'
		});
		window.onbeforeprint = function () {
			lazyLoadInstance.loadAll();
		};
	}

	static block_cover() {
		$('.wp-block-cover:has(.wp-block-cover__inner-container a)').attr('data-trigger', 'wpiris-click')
	}

	static obfuscation() {
		let element = "[data-obf]";
		if ($(element).length > 0) {
			if (!Modernizr.touchevents) {
				let ctrl = 0;
				$(window).on('keydown', function (e) {
					if (e.which == 17 || e.which == 91) {
						ctrl = true;
					}
				});
				$(window).on('keyup', function () {
					ctrl = false;
				});

				$(document).on('mouseup', element, function (e) {
					let url = atob($(this).attr('data-obf'));
					let target = $(this).attr('data-obf-target');
					if (e.which == 1 || e.which == 2) {
						let blank = false;
						if (e.which == 2) {
							blank = true;
						} // Clic molette
						if (target == "_blank") {
							blank = true;
						}
						if (blank || ctrl) {
							window.open(url, '_blank');
						} else {
							window.location = url;
						}
						return false;
					}

					return false;

				});
			} else {
				$(document).on('click', element, function (e) {
					let blank = false;
					let url = atob($(this).attr('data-obf'));
					let target = $(this).attr('data-obf-target');
					if (target == "_blank") {
						blank = true;
					}
					if (blank) {
						window.open(url, '_blank');
					} else {
						window.location = url;
					}
					return false;
				});
			}
		}
	}

	static sticky_navbar() {

		let last_scroll = 0,
			direction = null,
			trigger_height = $('.navbar').outerHeight(),
			$element = $('.navbar');

		// On scroll
		$(window).scroll(function () {

			let scroll = $(window).scrollTop();

			if (scroll - last_scroll > 150 || last_scroll - scroll > 150) {
				// Determine wich direction
				if (scroll > last_scroll) {
					direction = 'bottom';
				} else {
					direction = 'top';
				}
				last_scroll = scroll;
			}


			// Set sticky when scroll up
			if ((direction === "bottom") && (scroll > trigger_height)) {
				$element.addClass('is-hidden');
			} else {
				$element.addClass('is-sticky');
				$element.removeClass('is-hidden');
			}

			// Remove class if scroll top
			if (scroll < 1) {

				$element.removeClass('is-sticky');
			}
		});
	}

	static show_more() {
		let trigger = "[data-trigger='wpiris-show-more']";
		$(document).on('click', trigger, function (e) {
			e.preventDefault();
			let attr = $(this).attr('href');
			$(attr).toggleClass('show');
			$(this).hide();
		});
	}

	static isotope() {

		const grids = document.querySelectorAll('[data-isotope-grid]'),
			$grid = $('[data-isotope-grid]'),
			$filters = $('[data-isotope-filters]'),
			current_class = 'current';

		if ($grid.length > 0) {

			grids.forEach(grid => {
				// Init isotop
				var isotope = new Isotope(grid, {
					itemSelector: '[data-isotope-item]',
					layoutMode: 'fitRows',
					fitRows: {
						columnWidth: '[data-isotope-grid-sizer]',
						gutter: '[data-isotope-gutter-sizer]',
					},
				});

				// Filters
				if ($filters.length > 0) {

					// Filter items on button click
					$filters.on('click', 'a', function (e) {
						e.preventDefault();
						let filter_value = $(this).attr('data-isotope-filter');
						isotope.arrange({filter: filter_value});
					});

					// Change class on click
					$filters.each(function (i, button_group) {
						let $button_group = $(button_group);
						$button_group.on('click', 'a', function () {
							$button_group.find('.' + current_class).removeClass(current_class);
							$(this).addClass(current_class);
						});
					});
				}
			})

		}
	}

	static live_hour() {

		const $element = $("[data-id='wpiris-live-hour']");

		setInterval(function () {
			let date = new Date();
			let h = date.getHours();
			let m = date.getMinutes();

			if (h < 10) h = '0' + h;
			if (m < 10) m = '0' + m;

			$element.html(h + ':' + m);
		}, 1000);
	}

	static live_toggle() {
		const trigger = "[data-id='wpiris-live-tab']",
			$element = $("#live [data-id='wpiris-accelerator']");

		$element.eq(0).addClass('show');

		$(document).on('click', trigger, function (e) {
			e.preventDefault();
			let target = $(this).attr('href');
			$(trigger).removeClass('active');
			$(this).addClass('active');
			$('.liveContainer').removeClass('show');
			$('.liveContainer' + target).addClass('show');
		})
	}

	static notification_modal() {
		const $element = $('[data-id="notification-modal"]'),
			delay = $element.attr('data-notification-delay') * 1000,
			cookie_name = $element.attr('data-notification-cookie-name'),
			cookie_duration = $element.attr('data-notification-cookie-duration'),
			has_cookie = get_cookie(cookie_name);

		if ($element.length > 0) {
			if (!cookie_name) {
				setTimeout(function () {
					$element.modal('show');
				}, delay);
			} else if (has_cookie === 'false') {
				setTimeout(function () {
					$element.modal('show');
					set_cookie(cookie_name, true, cookie_duration);
				}, delay);
			}
		}
	}

	static switch() {
		const trigger = '[data-trigger="wpiris-switch"]',
			trigger_mode = '[data-switch-value]';
		let new_value = 0;

		if (trigger.length > 0) {
			$(document).on('click', trigger, function (e) {
				e.preventDefault();
				let value = $(this).attr('data-switch-active');
				$(this).toggleClass('switch--checked');

				if (0 == value) {
					$(this).attr('data-switch-active', 1);
					new_value = 1;

				} else {
					$(this).attr('data-switch-active', 0);
					new_value = 0;
				}

				$(document).trigger('wpiris-switch-click', {
					value: new_value,
				});
			});
		}

		if (trigger_mode.length > 0 && trigger.length > 0) {
			$(document).on('click', trigger_mode, function (e) {
				e.preventDefault();
				let value = $(this).attr('data-switch-value');
				$(trigger).attr('data-switch-active', value);

				if (0 == value) {
					$(trigger).removeClass('switch--checked');
				} else {
					$(trigger).addClass('switch--checked');
				}

				$(document).trigger('wpiris-switch-click', {
					value: value,
				});
			});
		}
	}

	static faq() {
		const element = '[data-id="wpiris-faq"]';

		if (element.length > 0) {
			$('[data-id="wpiris-faq-list"]').on('shown.bs.collapse', function () {
				$('[data-id="wpiris-faq-item"]').removeClass('open');
				let parent = $('.faqItemAnswer.show').parent();
				parent.addClass('open');
			});
			$('[data-id="wpiris-faq-list"]').on('hidden.bs.collapse', function () {
				$('[data-id="wpiris-faq-item"]').removeClass('open');
			});
		}
	}


	static translate() {
		const trigger = '[data-trigger="glt-selector"]',
			$element = $('[data-id="glt-selector"]');

		$( trigger ).on( 'click', function ( e ) {
			e.preventDefault();
			$element.fadeIn(200);
		} );

		$element.on( 'click', function ( e ) {
			if ( !$( event.target ).closest( '.gltSelectorInner' ).length ) {
				$( this ).fadeOut(200);
			}
		} );
	}
}
